import { createEvent } from "ics";
import { DateTime } from "luxon";
import { NextAppointment } from "./appointment-helpers";

export async function createIcsFile({
    nextAppointment,
    fileName,
    timeZone,
    calendarEventTitle,
    language,
}: {
    nextAppointment: NextAppointment;
    fileName: string;
    timeZone: string;
    calendarEventTitle: string;
    language: "en" | "fr";
}): Promise<{ url: string; icsFile: File } | null> {
    try {
        const nextAppointmentDatetime = nextAppointment.dateTime;

        const eventDate = DateTime.fromISO(nextAppointmentDatetime, {
            zone: timeZone,
        });

        const start: [number, number, number, number, number] = [
            eventDate.year,
            eventDate.month,
            eventDate.day,
            eventDate.hour,
            eventDate.minute,
        ];

        const icsFile: File = await new Promise((resolve, reject) => {
            createEvent(
                {
                    title: calendarEventTitle,
                    start: start,
                    startInputType: "local",
                    startOutputType: "local",
                    duration: { minutes: 30 },
                    location:
                        nextAppointment?.clinic?.address ?? language === "en"
                            ? "Phone Appointment"
                            : "Rendez-vous téléphonique",
                },
                (error, value) => {
                    if (error) {
                        reject(error);
                    } else {
                        resolve(
                            new File([value], fileName, {
                                type: "text/calendar",
                            })
                        );
                    }
                }
            );
        });
        const url = URL.createObjectURL(icsFile as Blob);
        return {
            url,
            icsFile,
        };
    } catch (error) {
        console.error("Failed to create ICS file:", error);
        return null;
    }
}
