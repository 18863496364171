import React from "react";

export function CheckIcon(): JSX.Element {
    return (
        <svg
            width="80"
            height="80"
            viewBox="0 0 24 24"
            fill="none"
            stroke="green"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            aria-hidden="true"
        >
            <circle cx="12" cy="12" r="10" />
            <path d="M9 12l2 2 4-4" />
        </svg>
    );
}

export function ClockIcon(): JSX.Element {
    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
        >
            <path
                d="M17.125 18.4851L18.875 16.7351L14.25 12.1101V6.36011H11.75V13.1101L17.125 18.4851ZM13 25.1101C11.2708 25.1101 9.64583 24.782 8.125 24.1257C6.60417 23.4695 5.28125 22.5789 4.15625 21.4539C3.03125 20.3289 2.14062 19.0059 1.48438 17.4851C0.828125 15.9643 0.5 14.3393 0.5 12.6101C0.5 10.8809 0.828125 9.25594 1.48438 7.73511C2.14062 6.21427 3.03125 4.89136 4.15625 3.76636C5.28125 2.64136 6.60417 1.75073 8.125 1.09448C9.64583 0.438232 11.2708 0.110107 13 0.110107C14.7292 0.110107 16.3542 0.438232 17.875 1.09448C19.3958 1.75073 20.7188 2.64136 21.8438 3.76636C22.9688 4.89136 23.8594 6.21427 24.5156 7.73511C25.1719 9.25594 25.5 10.8809 25.5 12.6101C25.5 14.3393 25.1719 15.9643 24.5156 17.4851C23.8594 19.0059 22.9688 20.3289 21.8438 21.4539C20.7188 22.5789 19.3958 23.4695 17.875 24.1257C16.3542 24.782 14.7292 25.1101 13 25.1101ZM13 22.6101C15.7708 22.6101 18.1302 21.6361 20.0781 19.6882C22.026 17.7403 23 15.3809 23 12.6101C23 9.83927 22.026 7.4799 20.0781 5.53198C18.1302 3.58407 15.7708 2.61011 13 2.61011C10.2292 2.61011 7.86979 3.58407 5.92188 5.53198C3.97396 7.4799 3 9.83927 3 12.6101C3 15.3809 3.97396 17.7403 5.92188 19.6882C7.86979 21.6361 10.2292 22.6101 13 22.6101Z"
                fill="black"
            />
        </svg>
    );
}

export function MapPinIcon(): JSX.Element {
    return (
        <svg
            width="32"
            height="36"
            viewBox="0 0 20 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
        >
            <path
                d="M10 13.1101C10.6875 13.1101 11.276 12.8653 11.7656 12.3757C12.2552 11.8861 12.5 11.2976 12.5 10.6101C12.5 9.92261 12.2552 9.33407 11.7656 8.84448C11.276 8.3549 10.6875 8.11011 10 8.11011C9.3125 8.11011 8.72396 8.3549 8.23438 8.84448C7.74479 9.33407 7.5 9.92261 7.5 10.6101C7.5 11.2976 7.74479 11.8861 8.23438 12.3757C8.72396 12.8653 9.3125 13.1101 10 13.1101ZM10 22.2976C12.5417 19.9643 14.4271 17.8445 15.6562 15.9382C16.8854 14.032 17.5 12.3393 17.5 10.8601C17.5 8.58927 16.776 6.7299 15.3281 5.28198C13.8802 3.83407 12.1042 3.11011 10 3.11011C7.89583 3.11011 6.11979 3.83407 4.67188 5.28198C3.22396 6.7299 2.5 8.58927 2.5 10.8601C2.5 12.3393 3.11458 14.032 4.34375 15.9382C5.57292 17.8445 7.45833 19.9643 10 22.2976ZM10 25.6101C6.64583 22.7559 4.14062 20.1049 2.48438 17.657C0.828125 15.2091 0 12.9434 0 10.8601C0 7.73511 1.00521 5.24552 3.01562 3.39136C5.02604 1.53719 7.35417 0.610107 10 0.610107C12.6458 0.610107 14.974 1.53719 16.9844 3.39136C18.9948 5.24552 20 7.73511 20 10.8601C20 12.9434 19.1719 15.2091 17.5156 17.657C15.8594 20.1049 13.3542 22.7559 10 25.6101Z"
                fill="black"
            />
        </svg>
    );
}
