import Axios from "axios";
import { LastAppointmentProvider } from "context/last-appointment";
import { get } from "lodash";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { IAnalyticsEventTracker, IAnalyticsService } from "utils/analytics";
import ENDPOINTS from "utils/endpoints";
import AppContext from "./context/app";
import User, {
    getLastAppointment,
    upcomingScreeningAppointment,
} from "./models/user";
import EmailConfirmationPage from "./pages/email-confirmation";
import HomePage from "./pages/home";
import LoginPage from "./pages/login";
import PasswordForgottenPage from "./pages/password-forgotten";
import PasswordResetPage from "./pages/password-reset";
import ProfilePage from "./pages/profile";
import SignUpPage from "./pages/sign-up";
import TfaInputPage from "./pages/tfa-input";
import TfaSettingsPage from "./pages/tfa-settings";
import UnsubscribePage from "./pages/unsubscribe";
// Pages
import WidgetPage from "./pages/widgets";
import { getTranslations } from "./translations";
import { createAnalyticsService } from "./utils/analytics/serviceFactory";
import { IFeatureManager } from "./utils/feature-management";
import { AmplitudeFlagAndExperimentManager } from "./utils/feature-management/amplitude";
import {
    getPersistedAuthToken,
    getPersistedUserLanguage,
    persistAuthToken,
    persistUserLanguage,
} from "./utils/local-storage";
import ROUTES from "./utils/routes";
import { UserLanguage } from "./utils/types";

const App: React.FunctionComponent = () => {
    const location = useLocation();
    // States and refs

    const [authToken, setAuthToken] = useState<string | null>(
        getPersistedAuthToken()
    );
    const [authUser, setAuthUser] = useState<User | null>(null);
    const [language, setLanguage] = useState<UserLanguage>(
        getPersistedUserLanguage()
    );
    const [analytics, setAnalytics] = useState<IAnalyticsService | null>(null);
    const [
        featureManager,
        setFeatureManager,
    ] = useState<IFeatureManager | null>(null);

    // Effects
    useEffect(() => {
        let analyticsService: IAnalyticsService;

        if (!analytics) {
            // NOTE: we force String conversion of the env variable to ensure that the appropriate
            // Javascript type String is assigned to the environment value during build and deployment
            const cdpConfig = {
                writeKey: process.env.REACT_APP_CUSTOMERIO_CDP_WRITE_KEY ?? "",
                cdnURL: process.env.REACT_APP_CUSTOMERIO_CDP_CDN_URL ?? "",
                isProxyCdnURL:
                    String(
                        process.env.REACT_APP_CUSTOMERIO_CDP_IS_PROXY_CDN_URL
                    ) === "true",
            };
            const webConfig = {
                apiHostURL: process.env.REACT_APP_PLAUSIBLE_HOST_URL ?? "",
                domainURL: process.env.REACT_APP_PLAUSIBLE_DOMAIN_URL ?? "",
                trackLocalhost:
                    String(process.env.REACT_APP_PLAUSIBLE_TRACK_LOCALHOST) ===
                    "true",
            };

            analyticsService = createAnalyticsService({
                cdp: cdpConfig,
                web: webConfig,
            });

            setAnalytics(analyticsService);
        }

        if (!featureManager) {
            // NOTE: This check allows us to run experiments in any environment which the API KEY
            // is defined. If key is not defined, app continues execution instead of throwing errors
            if (
                process.env.REACT_APP_AMPLITUDE_EXPERIMENT_API_KEY &&
                process.env.REACT_APP_AMPLITUDE_EXPERIMENT_SERVER_URL
            ) {
                const startAmplitudeFlagAndExperimentManager = async (): Promise<void> => {
                    const eventTracker =
                        (analyticsService.cdp() as IAnalyticsEventTracker) ??
                        null;
                    const manager = new AmplitudeFlagAndExperimentManager(
                        process.env.REACT_APP_AMPLITUDE_EXPERIMENT_API_KEY ??
                            "",
                        process.env
                            .REACT_APP_AMPLITUDE_EXPERIMENT_SERVER_URL as string,
                        String(
                            process.env.REACT_APP_AMPLITUDE_EXPERIMENT_DEBUG
                        ) === "true",
                        eventTracker
                    );

                    if (manager) {
                        const canStart = await manager.start();
                        if (canStart) {
                            setFeatureManager(manager);
                        }
                    }
                };

                startAmplitudeFlagAndExperimentManager();
            } else {
                console.warn("Feature Manager is not configured");
            }
        }
    }, []);

    useEffect(() => {
        analytics?.web()?.pageView();
    }, [location, analytics]);

    useLayoutEffect(() => {
        // Persist the token
        persistAuthToken(authToken);

        // If no auth token, no auth user
        if (!authToken) setAuthUser(null);

        // Update axios configs to change the used auth token
        const requestInterceptor = Axios.interceptors.request.use(
            (config) => {
                config.headers["x-access-token"] = authToken;
                return config;
            },
            (error) => Promise.reject(error)
        );

        return () => {
            Axios.interceptors.request.eject(requestInterceptor);
        };
    }, [authToken, setAuthUser]);

    useEffect(() => {
        // Fetch auth user
        if (authToken !== null && authToken !== undefined) {
            fetchMe();
        }
    }, [authToken]);

    useEffect(() => {
        if (authUser !== null) {
            if (analytics !== null) {
                const nextAppointment = upcomingScreeningAppointment(authUser);
                const lastAppointment = getLastAppointment(authUser);
                analytics
                    .cdp()
                    ?.identifyUser(
                        authUser.firstName ?? "",
                        authUser.email ?? "",
                        authUser.phone ?? "",
                        authUser.lang ?? "",
                        nextAppointment?.datetime ?? "",
                        lastAppointment?.datetime ?? "",
                        authUser.created_at ?? ""
                    );
            }

            if (featureManager !== null) {
                const fetchFeatureExperimentUser = async (): Promise<void> => {
                    await featureManager.configureUser(authUser.email);
                };

                fetchFeatureExperimentUser();
            }
        }
    }, [authUser, analytics, featureManager]);

    useEffect(() => {
        const responseInterceptor = Axios.interceptors.response.use(
            (res) => res,
            (error) => {
                const errorStatus = get(
                    error,
                    ["response", "data", "status"],
                    400
                );
                if (errorStatus === 401 || errorStatus === 403)
                    setAuthToken(null);
                return Promise.reject(error);
            }
        );

        return () => {
            Axios.interceptors.request.eject(responseInterceptor);
        };
    }, [setAuthToken]);

    useEffect(() => {
        persistUserLanguage(language);
        if (authUser && authUser.lang !== language) updateUserLanguage();
    }, [language, authUser]);

    // Network

    const fetchMe = async () => {
        try {
            const { data } = await Axios.get(ENDPOINTS.ME);
            setAuthUser(data);
        } catch (e) {
            setAuthToken(null);
        }
    };

    const updateUserLanguage = async () => {
        try {
            await Axios.put(ENDPOINTS.ME, { lang: language });
            if (authUser) setAuthUser({ ...authUser, lang: language });
        } catch (e) {
            console.log(e);
        }
    };

    // Rendering

    const translations = getTranslations(language);

    return (
        <AppContext.Provider
            value={{
                authToken,
                setAuthToken,
                authUser,
                setAuthUser,
                language,
                setLanguage,
                translations,
                analytics,
                setAnalytics,
                featureManager,
                setFeatureManager,
            }}
        >
            <LastAppointmentProvider>
                <Switch>
                    <Route exact path="/widgets">
                        <WidgetPage />
                    </Route>
                    <Route exact path={ROUTES.LOGIN}>
                        <LoginPage />
                    </Route>
                    <Route exact path={ROUTES.TFA_INPUT}>
                        <TfaInputPage />
                    </Route>
                    <Route exact path={ROUTES.TFA_SETTINGS}>
                        <TfaSettingsPage />
                    </Route>
                    <Route exact path={ROUTES.EMAIL_CONFIRMATION}>
                        <EmailConfirmationPage />
                    </Route>
                    <Route exact path={ROUTES.PASSWORD_FORGOTTEN}>
                        <PasswordForgottenPage />
                    </Route>
                    <Route exact path={ROUTES.PASSWORD_RESET}>
                        <PasswordResetPage />
                    </Route>
                    <Route exact path={ROUTES.SIGN_UP}>
                        <SignUpPage />
                    </Route>
                    <Route exact path={ROUTES.UNSUBSCRIBE}>
                        <UnsubscribePage />
                    </Route>

                    {!authToken && <Redirect to={ROUTES.LOGIN} />}
                    <Route path={ROUTES.HOME}>
                        <HomePage />
                    </Route>
                    <Route exact path={ROUTES.PROFILE}>
                        <ProfilePage />
                    </Route>
                    <Redirect to={ROUTES.HOME} />
                </Switch>
            </LastAppointmentProvider>
        </AppContext.Provider>
    );
};

export default App;
