import { Translations } from "./types";

export default {
    appointmentConfirmation: {
        title: "Ton rendez-vous est confirmé. Yay!",
        subtitle:
            "Un courriel avec les infos ci-dessous t'a été envoyé. Si tu as des questions, écris-nous à ",
        reminders: {
            title: "N'oublie pas !",
            bringRAMQ: {
                main: "Apporte ta RAMQ",
                note:
                    " et un moyen de paiement. Pas de RAMQ? Amène une pièce d'identité avec photo.",
            },
            beOnTime: { main: "Sois pile à l'heure !" },
            cancelEarly: {
                main: "Annule au moins 4 heures avant",
                note: " pour éviter des frais de 35$.",
            },
        },
        icsFileTitle: "Rendez-vous Prelib",
        serviceDescription: {
            regular:
                "Le suivi téléphonique est assuré par les <strong>médecins</strong> de <strong>Clinique médicale en santé sexuelle inc.</strong>",
            express:
                "Le suivi express est assuré par les <strong>infirmières</strong> de <strong>Centre de prévention en santé sexuelle inc.</strong>",
        },
    },
    appointmentsSection: {
        upcomingAppointments: "Tes rendez-vous à venir",
        pastAppointments: "Tes rendez-vous passés",
        noAppointment: "Tu n’as pas encore de rendez-vous.",
        clickHereToSchedule: "Clique ici pour faire un dépistage.",
        appointmentCard: {
            cancelButtonText: "Annuler",
            editButtonText: "Modifier",
            rescheduleButton: "Reporter",
            retakeQuestionnaire: "Remplir le questionnaire à nouveau",
            cancellationIndicator: "ANNULÉ",
            staffCancellationIndicator: "DOIT ÊTRE REPORTÉ",
            consultButtonText: "Consulter",
            dateIndicator: (date) => `Rendez-vous de dépistage du ${date} `,
            medicalTestsIndicator: "Examens à passer",
            resultsIndicator: "Tes résultats",
            missed: "Manqué",
            missedMessage:
                "Tu as manqué ton rendez-vous, mais il est encore temps de le modifier.",
            resultsNotReadyLine1: "Tes résultats sont toujours en attente,",
            resultsNotReadyLine2: "mais ils ne devraient pas tarder à arriver.",
            missedAppointmentMessage:
                "On dirait que tu as manqué ton rendez-vous et que ton questionnaire n'est plus valide. Pour prendre un autre rendez-vous, remplis le questionnaire médical à nouveau.",
        },
        phoneAppointmentCard: {
            chooseButtonText: "Choisir",
            editButtonText: "Changer",
            consultButtonText: "Consulter",
            rejectButtonText: "Refuser",
            title: "Rendez-vous téléphonique",
            independantTitle: "Rendez-vous téléphonique",
            prepTitle: "Rendez-vous téléphonique de la PrEP",
            rejectedIndicator: "Refusé",
            canceledIndicator: "Annulé",
            dateIndicator: (date) => `Rendez-vous téléphonique du ${date}`,
            prepDateIndicator: (date) =>
                `Rendez-vous téléphonique de la PrEP du ${date}`,
            independantDateIndicator: (date) =>
                `Rendez-vous téléphonique du ${date}`,
            descriptionLine1: (assAppDate) =>
                `Rendez-vous téléphonique en lien avec ton rendez-vous de dépistage du ${assAppDate}.`,
            prepDescription:
                "L'appel avec la pharmacie participante permet de recueillir tous les renseignements requis pour déterminer si elle peut ou non amorcer la PrEP et d'en assurer les suivis nécessaires.",
            prepDescription2:
                "Si le rendez-vous téléphonique n'est pas complété ou si tu n'es pas éligible, alors le prélèvement de créatinine sera annulé.",
            descriptionLine2:
                "Un membre de notre personnel te contactera durant la plage horaire sélectionnée.",
            missedMessage:
                "On dirait que tu as manqué ton rendez-vous téléphonique. Tu peux le reprendre dès maintenant.",
            chooseDate:
                "Tu as été invité.e à un rendez-vous téléphonique. Choisis la date et l'heure qui te conviennent.",
        },
        scheduleModal: {
            confirmButtonText: "Confirmer",
            exitButtonText: "Annuler",
            payButtonText: "Payer",
        },
        cancellationModal: {
            cancellationButtonText: "Annuler ton rendez-vous",
            header: (date) => `Modifier ton rendez-vous du ${date}`,
            rescheduleButtonText: "Choisir une autre date*",
            feesMightApply: "*Certains frais peuvent s'appliquer.",
            faqUrl: "https://www.prelib.com/fr?faq=14#faqs",
            phoneAppointmentRejected: "Ton rendez-vous a été refusé.",
            cancelAppointmentNotAllowed:
                "Il est n’est pas permis d’annuler un rendez-vous passé ou ayant lieu dans moins de 4 heures.",
        },
        cancellationConfirmModal: {
            title: "Es-tu sûr·e de vouloir annuler ton rendez-vous?",
            phoneTitle:
                "Es-tu sûr·e de vouloir annuler ton rendez-vous téléphonique?",
            prepEligibility:
                "Si tu annules l'appel avec le pharmacien, les services en lien avec la PrEP ne pourront être effectués. Tu pourras tout de même te présenter à ton rendez-vous de dépistage d'ITSS.",
            content:
                "Prends note que les frais pour l'évaluation médicale en ligne ne sont pas remboursables.",
            confirmButtonText: "Oui, annule mon rendez-vous",
            exitButtonText: "Non, conserve mon rendez-vous",
            appointmentCancelled: "Ton rendez-vous a été annulé.",
        },
        creditInfoModal: {
            cardBillingAddressLabel: "Adresse de facturation",
            cardExpirationLabel: "Date d'expiration",
            cardHolderNameLabel: "Nom du titulaire de la carte",
            content:
                "Confirme les renseignements relatifs à ta carte de crédit pour le paiement des\u00A0",
            modifyButton: "Modifier",
            link: "frais administratifs de 35 $ (taxes en sus).",
            faqUrl: "https://www.prelib.com/fr?faq=14#faqs",
            payButton: "Payer",
        },
        gatherCreditInfoModal: {
            content:
                "Inscris les renseignements relatifs à ta carte de crédit pour le paiement des\u00A0",
            link: "frais administratifs de 35 $ (taxes en sus).",
        },
        rescheduleAppointmentModal: {
            title: "Choisis ta clinique et ta plage horaire.",
            prepSubtitle:
                "L'appel avec le pharmacien doit précéder ton rendez-vous de prélèvement du",
            confirmButtonText: "Choisir",
            exitButtonText: "Annuler",
        },
        feedbackModal: {
            title: "Sondage sur la satisfaction",
            letUsKnowHowItWent: "Fais-nous part de ton expérience chez Prelib.",
            satisfactionLevel:
                "Quel est ton niveau de satisfaction concernant le service obtenu chez Prelib?",
            recommendationProbability:
                "Quelles sont les chances que tu recommandes Prelib à ton entourage?",
            recommendationJustification:
                "Dans tes propres mots, pourquoi as-tu donné cette note?",
            phoneCommunicationAgreement:
                "Accepterais-tu qu’un membre de notre équipe communique avec toi par téléphone afin d’améliorer notre service?",
            yes: "Oui",
            no: "Non",
            textAreaPlaceholder: "Écrire une note ...",
            confirmButtonText: "Soumettre",
            exitButtonText: "Annuler",
            notifications: {
                satisfactionQuestionnaireSentSuccess:
                    "Formulaire de satisfaction envoyé.",
                satisfactionQuestionnaireSentError:
                    "Erreur à l'envoi du formulaire de satisfaction.",
            },
        },
        googleReviewModal: {
            title: "Wow, génial!",
            subtitle: "Laisse un peu d'amour sur Google",
            reviews: [
                "Le personnel a été très rapide, serviable et sympathique pour résoudre le problème.",
                "Mon expérience a été parfaite.",
            ],
            reviewButtonText: "Laisser un avis",
            postponeButtonText: "Me demander plus tard",
            refuseButtonText: "Non merci",
            notifications: {
                publicReviewPreferenceSaveSuccess: "Préférence enregistrée.",
                publicReviewPreferenceSaveError:
                    "Erreur : ta préférence n'a pas pu être enregistrée",
            },
        },
    },
    dateSection: {
        titleWithPrep: "Prise de rendez-vous",
        prepSubtitle: "Solution PrEP",
        prepDescription:
            "Grâce au programme Solution PrEP, tu peux obtenir la PrEP en tout simplicité. Pour bénéficier de ce service, un rendez-vous téléphonique avec un pharmacien participant est requis et permet de vérifier ton éligibilité. Si tu ne souhaites pas prendre la PrEP via le programme Solution PrEP, tu peux tout de même prendre rendez-vous pour un dépistage d’ITSS sans problème et ensuite obtenir la PrEP via la clinique médicale et la pharmacie de ton choix.",
        prepConsent:
            "*Je consens à ce qu'une pharmacie participante au programme Solution PrEP reçoive mes coordonnées et les informations pertinentes à ma prescription et qu'elle me contacte selon la disponibilité choisie.",
        makePrepAppointmentButtonText: "Prendre un RDV*",
        abortPrepCheckboxText: "Non, je ne veux pas la PrEP",
        prepNotDecidedError:
            "Veuillez indiquer si vous êtes intéressé(e) par le suivi de PrEP avec nous.",
        header: "Choisis ta clinique et ta plage horaire.",
        screeningSectionTitle: "Dépistage",
        screeningSectionDescription:
            "Il est important que le rendez-vous de dépistage soit effectué après le rendez-vous téléphonique avec un pharmacien participant.",
        clinicsAreLoading:
            "La liste des cliniques de dépistage est en cours de chargement",
        pharmacistCalendarIsLoading:
            "Le calendrier pharmacien est présentement en cours de chargement",
        submitButtonText: "Soumettre",
    },
    dateSelectorComponent: {
        choseDateLabel: "Choisis une date.",
        choseHourLabel: "Heure",
        noAvailability: "Aucune disponibilité",
        noDateAvailability:
            "Aucune disponibilité en ce moment. Réessaie demain.",
    },
    emailConfirmation: {
        goToHomeButton: "Voir ton profil",
        header: "Confirmation de ton adresse courriel",
        invalidTokenError: "Non valide ou expiré&#8239;!",
        successMessage: "Ton adresse courriel est confirmée&#8239;!",
    },
    footer: {
        needHelp: "Besoin d’aide?",
        termsAndConditions: "Termes et conditions",
        termsAndConditionsLink: "https://prelib.com/termes/",
        privacyPolicy: "Politique de confidentialité",
        privacyPolicyLink: "https://prelib.com/politique-de-confidentialite/",
    },
    forgottenPassword: {
        header: "Mot de passe oublié",
        instruction:
            "Entre ton adresse courriel ci-dessous et nous t’enverrons un courriel t'invitant à reconfigurer ton mot de passe.",
        rhetoricalQuestion: "Tu as oublié ton mot de passe ?",
        emailLabel: "Adresse courriel",
        invalidEmailError: "Adresse courriel non valide",
        sendButtonText: "Envoyer",
        successMessage: "Un courriel t'a été envoyé.",
    },
    homePage: {
        loadingInfo: "Chargement de tes informations",
        topMenu: {
            completeScreeningButtonText: "Confirmer le rendez-vous",
            disconnectButtonText: "Déconnexion",
            greetingsText: "Salut",
            makeScreeningButtonText: "Faire un dépistage",
            updateProfile: "Modifier mon profil",
        },
        logo: {
            headerTitle: "Bienvenue chez Prelib",
        },
        subheaderTab: {
            screeningTitle: "Faire un dépistage",
            myAppointmentTitle: "Mes rendez-vous",
            myPrepTitle: "Mon espace PrEP",
        },
        tooltipTab: {
            screening: "Un rendez-vous est déjà inscrit à ton dossier.",
            phoneAppointment:
                "Tu dois réserver et compléter ton rendez-vous téléphonique de suivi avant de pouvoir faire un nouveau dépistage.",
        },
        payMissedAppointmentFeesPopup: {
            title:
                "Puisque vous avez manqué votre dernier rendez-vous, vous devez payer 35$ avant de prendre un nouveau rendez-vous.",
        },
    },
    unsubscribeConfirmation: {
        goToHomeButton: "Voir mon profil",
        header: "Confirmation du désabonnement",
        invalidTokenError: "Le jeton est invalide&#8239;!",
        successMessage:
            "Tu n’es plus abonné.e aux courriels automatisés de Prelib. Tu pourras toujours t'abonner à nouveau en cliquant sur « M’abonner aux courriels de suivi » dans la section « Renseignements personnels » de ton dossier en ligne.",
    },
    infoConfirmationSection: {
        birthDateLabel: "Indique ta date de naissance\u202F!",
        birthDateConfirmation: "Confirme ta date de naissance",
        header: "Informations personnelles",
        completeButtonText: "Compléter",
        confirmInfo: "Confirmer les informations",
    },
    login: {
        alreadyHasAccountLink: "Tu as déjà un compte.",
        createAccountLink: "Créer un compte",
        emailConfirmationMessage:
            "Tu dois maintenant confirmer ton adresse courriel.",
        emailLabel: "Adresse courriel",
        firstNameLabel: "Prénom",
        firstNameWarning: "Tel que figurant sur la carte RAMQ",
        forgottenPasswordLink: "Mot de passe oublié?",
        invalidEmailError: "Courriel non valide",
        invalidPasswordError: "Mot de passe non valide",
        loginHeader: "Mon dossier",
        lastNameLabel: "Nom de famille",
        lastNameWarning: "Tel que figurant sur la carte RAMQ",
        loginButtonText: "Connexion",
        consentMessage:
            'J\'ai lu et accepte les <a href="https://prelib.com/termes" rel="noopener noreferrer" target="_blank"> termes d\'utilisation </a> et la <a href="https://prelib.com/vie-privee" rel="noopener noreferrer" target="_blank"> politique de confidentialité </a> de Prelib.',
        passwordErrors: {
            maxLength: "Ton mot de passe doit contenir 128 caractères maximum.",
            minLength: "Ton mot de passe doit contenir 10 caractères minimum.",
            needLowercase:
                "Ton mot de passe doit contenir au moins une lettre minuscule.",
            needNumber: "Ton mot de passe doit contenir au moins un chiffre.",
            needSpecialSymbol:
                "Ton mot de passe doit contenir au moins un symbole non alphanumérique.",
            needUppercase:
                "Ton mot de passe doit contenir une lettre majuscule.",
        },
        passwordWarning:
            "Ton mot de passe est strictement confidentiel et ne doit pas être divulgué à des tiers.",
        passwordLabel: "Mot de passe",
        tfaLabel: "Vérification en 2 étapes",
        tfaContext:
            "Pour la sécurité de tes données, ton compte sera désormais protégé par une authentification en 2 étapes. Lors de la connexion, tu devras confirmer ton identité à l’aide d’un code à 6 chiffres. De quelle façon souhaites-tu recevoir ce code?</b>",
        byEmail: "Par courriel",
        bySms: "Par SMS",
        phoneNumberLabel: "Numéro de téléphone",
        phoneNumberPlaceholder: "(xxx) xxx-xxxx",
        phoneNumberError: "Numéro de téléphone invalide",
        requiredConsentError: "Consentement requis",
        requiredFieldError: "Champs requis",
        signUpButtonText: "Je m'inscris",
        signUpHeader: "Créer mon dossier Prelib",
        resendConfirmationEmail: "Renvoyer le courriel de confirmation à ",
        confirmationResent: "Le courriel de confirmation a été envoyé à ",
        accountCreated: "Ton dossier a été créé",
        resend: "Renvoyer le courriel de confirmation",
        formErrors: "Il y a des erreurs dans le formulaire",
        hotmailWarning:
            "Si vous utilisez @hotmail, les courriels Prelib risquent de se retrouver dans les courriels indésirables. Nous vous recommandons d'ajouter Prelib à votre liste de contacts pour ne manquer aucun courriel",
        warning: "Attention",
    },
    notification: {
        createdAppointment: "Ton rendez-vous a été prévu.",
        canceledAppointment: "Rendez-vous annulé",
        rescheduledAppointment: "Rendez-vous reporté",
        scheduledPhoneAppointment: "Rendez-vous téléphonique confirmé",
        appointmentUpdated: "Ton rendez-vous a été mis à jour.",
        retakeRejectedAppointment: "Reprise de rendez-vous refusée",
        unexpectedError: "Une erreur est survenue!",
    },
    upfrontPaymentSection: {
        sectionTitle: "Support médical",
        followUpChoices: {
            legend: "Sélectionne le support adapté à tes besoins",
            noRamqExplanation:
                "Ce suivi te permet d'accéder au dépistage sans carte RAMQ.",
            regular: {
                title: "Suivi téléphonique",
                description: [
                    "Résultats par <strong>téléphone</strong>",
                    "Résultats en <strong>10 jours</strong> en <em>moyenne</em>*",
                ],
                totalAmount: "41.50$",
                totalNoCharge: "Couvert par la RAMQ",
            },
            express: {
                title: "Suivi express",
                description: [
                    "Résultats par <strong>courriel</strong> (sauf si positif)",
                    "Résultats en <strong>5 jours</strong> en <em>moyenne</em>*",
                ],
                footer: "Question médicale? info@prelib.com.",
                totalAmount: "45$",
            },
        },
        totalLabel: "Total :",
        chargeWarning:
            "Toute modification à moins de 4 heures du rendez-vous entraîne des frais de 35$.",
        timeEstimateWarning: "* Les délais peuvent varier",
        paymentForm: {
            cardCvc: "CVD",
            cardExpiry: "MM/AA",
            cardLabel: "Carte de crédit",
            cardNumber: "Numéro de carte",
            payButtonPrefix: "Payer",
            noChargeButtonText: "Enregistrer carte de crédit",
            paymentOptionIntro: "Payer en",
            paymentOptionCash: "argent comptant",
        },
        legacyText: {
            noRamqChargeExplanation:
                "Votre carte sera débitée de 41,50 $ pour des frais non remboursables d’évaluation médicale électronique.",
            noRamqChargeExplanationNote:
                "En complétant cette transaction, vous aurez accès à des recommandations personnalisées et pourrez réserver votre rendez-vous.",
            noRamqHeader: "Paiement",
            ramqChargeExplanation:
                "Aucun frais n’est facturé avant votre consultation. Des frais de 35 $ s’appliquent si vous ne vous présentez pas ou si vous modifiez votre rendez-vous moins de 4 heures à l’avance.",
            ramqHeader: "Enregistrer vos infos de paiement",
        },
    },
    paymentSection: {
        cancelButton: "Annuler",
        cardExpirationDate: "Date d’expiration (MM/AA)",
        cardHolderAddress: "Adresse",
        cardHolderCity: "Ville",
        cardHolderName: "Nom du titulaire de la carte",
        cardHolderZipCode: "Code postal",
        cardNumber: "Numéro de la carte",
        cardSecurityCode: "Code de sécurité",
        chargeExplanation:
            "En acquittant ces frais, il te sera possible d’accéder aux recommandations personnalisées ainsi qu’à la prise de rendez-vous.",
        header: "Paiement",
        noRefundWarning:
            "Une somme non remboursable de 41,50\u00A0$ te sera facturée pour l'évaluation médicale électronique.",
        payButtonText: "Payer",
        privacyMessage:
            "Tes données sont protégées en utilisant le protocole standard de sécurité sur Internet SSL. Ces données sont cryptées et échangées avec un serveur HTTPS. Nous respectons ta confidentialité. Nous transmettrons uniquement ton nom, adresse et courriel au marchand.",
        applyCouponLabel: "Utiliser un code promotionnel",
        applyCouponButton: "Appliquer",
        couponDiscountLabel: "Rabais",
        delayDisclaimer:
            "* Le traitement du paiement peut prendre un certain temps. Laisse la page ouverte jusqu'à la confirmation.",
        chargeErrorNotification:
            "On dirait que ça n'a pas fonctionné. Pas de stress, vérifie les infos de ta carte et réessaie!",
    },
    profileSection: {
        addressLabel: "Adresse",
        addressPlaceHolder: "Adresse",
        birthDateLabel: "Date de naissance",
        bornGender: "Sexe assigné à la naissance",
        cancelTextButton: "Annuler",
        cardBillingAddressLabel: "Adresse de facturation",
        cardExpirationLabel: "Date d'expiration",
        cardHolderNameLabel: "Nom du titulaire de la carte",
        cityPlaceHolder: "Ville",
        emailRemindersDisabled: "Me désabonner des courriels de suivi",
        false: "Non",
        firstNameLabel: "Prénom",
        generalHealthHeader: "Santé Générale",
        jobLabel: "Profession",
        lastNameLabel: "Nom de famille",
        modifyLabel: "Modifier",
        modifyTextButton: "Modifier",
        paymentMethodHeader: "Méthode de paiement",
        personalInformationHeader: "Renseignements personnels",
        ramqNumberExpirationLabel: "Expiration de la RAMQ",
        ramqNumberLabel: "Numéro de carte RAMQ",
        ramqNumberError: "Numéro de carte RAMQ non valide",
        phoneNumberLabel: "Numéro de téléphone",
        phoneNumberError: "Numéro de téléphone non valide",
        selfDefinedGender: "Sexe",
        sexualHealthHeader: "Santé Sexuelle",
        choose: "Choisir",
        true: "Oui",
        zipCodePlaceHolder: "Code postal",
        followUpEmails: "Courriels de suivi",
        unsubscribe: "Te désabonner",
        subscribe: "T'abonner",
        confirmPhoneNumber: "Confirmer ton numéro de téléphone",
        tfaPreference: "Préférences pour l’authentification en 2 étapes",
        phoneNumberMustBeConfirmed:
            "Tu dois confirmer ton numéro de téléphone pour utiliser la messagerie texte comme méthode d’authentification à 2 étapes.",
        cancel: "Annuler",
        save: "Sauvegarder",
        password: "Mot de passe",
        requestPassword:
            "M'envoyer un lien de réinitialisation de mot de passe",
        sending: "Envoi en cours...",
        emailSent: "Courriel envoyé",
    },
    phoneConfirmationModal: {
        title: "Confirmation de ton numéro de téléphone",
        sentTo: (phone) =>
            `Un code de confirmation a été envoyé au ${phone} par messagerie texte.`,
        instructions:
            "Entre ton code de confirmation dans le champ ci-dessous pour confirmer ton numéro de téléphone.",
        notReceived: "Tu n’as pas reçu le code?",
        resend: "Envoyer un autre code",
        wrongPhone: "Mauvais numéro de téléphone?",
        changePhone: "Clique ici pour mettre à jour ton profil.",
        placeholder: "Code à 6 chiffres",
        submit: "Confirmer",
        invalid: "Code non valide",
        successMessage: "Ton numéro de téléphone a été confirmé",
    },
    questionnaireComponent: {
        blockText: "ou appuie sur <strong>Entrée</strong>",
        incompleteQuestionnaireError: "Formulaire non valide",
        noRamqButton: "Je ne suis pas inscrit.e à la RAMQ",
        completeBtn: "Compléter",
        okBtn: "OK",
        yearLbl: "Année",
        phoneNumber: "Numéro de téléphone",
        requiredFieldError: "Champ obligatoire",
        invalidFormError:
            "Il y a une ou plusieurs erreur(s) dans le formulaire",
        selectRequiredFieldError: "Tu dois choisir une réponse",
        ramqNumberInvalidError: "Numéro de carte RAMQ non valide",
        phoneInvalidError: "Numéro de téléphone non valide",
        underFifteenError:
            "Les services Prelib s’adressent à toutes personnes âgées de 15 ans et plus",
    },
    questionnaireSection: {
        completeButtonText: "Remplir",
        header: "Formulaire sur la santé générale",
        takeQuestionnaire: "Remplir le questionnaire",
        modal: {
            consentButtonText: "J’accepte.",
            consentHeader: "Consentement",
            consentFirstP:
                "En cliquant sur le bouton « J’accepte » ci-après, j’indique que je consens à ce que Prelib inc. (« Prelib ») et ses médecins effectuent une ou plusieurs téléconsultations dans le cadre d'un processus de dépistage asymptomatique en santé sexuelle via le questionnaire standardisé électronique. La téléconsultation est possible seulement pour les personnes physiquement situées au Québec. En cliquant sur le bouton « J’accepte », j’atteste être actuellement au Québec.",
            consentSecondP:
                "Je comprends et je reconnais (i) que Prelib a recours à tous les moyens technologiques possibles pour contrer les risques de violation de confidentialité ou de perte de définition des images lors de la transmission, par voie électronique, de ces renseignements confidentiels, (ii) qu’il existe des risques et des limitations liés à l’utilisation de la télémédecine et du présent site Web, y compris toutes limites technologiques liées à la téléconsultation et les risques dans le traitement des données, (iii) que je ne dois pas utiliser le présent site Web pour des urgences médicales, et (iv) que Prelib pourra collecter, utiliser et partager mes renseignements personnels (et médicaux) conformément à la Politique de confidentialité publiée sur le présent site Web.",
            consentThirdP:
                "J'autorise l'ouverture d'un dossier électronique du patient où seront déposées les informations transmises lors de cette ou ces consultations et qui maintiendra la confidentialité des renseignements personnels.",
            consentFourthP:
                "J’accepte la présente entente et je retiens les services de Prelib. Je consens aux soins de téléconsultation au moyen du site Web de Prelib.",
            continueButtonText: "Continuer",
            recommendationHeader: "Recommandations",
            recommendationSubHeader:
                "Prelib recommande fortement de consulter un médecin dans les situations suivantes :",
            recommendationFirstP: "Présence de symptômes d'ITSS",
            recommendationSecondP:
                "Exposition potentielle dans les 72 dernières heures lors d'une relation sexuelle à risque, afin d'évaluer si la prophylaxie post-exposition (PPE) est une option possible.",
            recommendationThirdP: "Grossesse",
            refuseButtonText: "Je refuse.",
        },
    },
    datetimePicker: {
        chooseAnHour: "Choisir une Heure",
        timePickerPlaceHolder: "Heure",
    },
    recommendationsSection: {
        restartQuestionnaire: "Remplir le questionnaire médical à nouveau",
        choseDateButtonText: "Choisir la date",
        updateTestSelection: "Mettre à jour ma sélection de tests",
        analScreening: "Dépistage anal",
        bloodScreening: "Dépistage sanguin",
        customRecommendationsPresentation:
            "Selon tes réponses au questionnaire, voici ce qui t'est conseillé\u00A0:",
        condomRecommendation:
            "L’utilisation du condom lors de toute relation sexuelle réduit nettement tes risques de contracter une ITSS.",
        drugSharingRecommendation:
            "Si tu consommes des drogues, ne partage pas ni ne réutilise jamais du matériel d’injection ou d’inhalation à usage unique.",
        extraCharges:
            "*À noter que les frais reliés à tes tests devront être réglés sur place.",
        header: "Recommandations",
        importantNewEvaluation:
            "Tu disposes de 45 jours pour prendre rendez-vous, sans quoi une réévaluation médicale sera nécessaire.",
        importantCancelTest:
            "Au moment du rendez-vous, il te sera toujours possible de retirer une analyse non souhaitée.",
        importantDisabled:
            "Si tu es une personne à mobilité réduite, écris-nous avant ton rendez-vous à info@prelib.com afin d’obtenir l’adresse pour accéder au centre.",
        importantFee:
            "Le paiement des frais de transport (5 à 15 $) et frais de laboratoire privé Dynacare, le cas échéant, sera exigé au moment du rendez-vous.",
        importantHeader: "IMPORTANT",
        importantSymptoms:
            "Si tu remarques la présence de symptômes avant ton rendez-vous, il est recommandé de consulter un médecin immédiatement.",
        medicalTestsPresentation:
            "À l’aide de tes réponses, une série de tests t’ont été prescrits. Tu peux modifier la sélection de tests si tu le souhaites\u00A0:",
        minimalTestsCountError: "Tu dois choisir au moins un test.",
        oralScreening: "Dépistage oral",
        ppeLink: "prophylaxie post-exposition (PPE).",
        ppeRecommendation:
            "Puisque tu as récemment eu une relation sexuelle à risque (moins de 72 heures), on te recommande fortement de visiter une clinique dès que possible afin d’évaluer si la prophylaxie post-exposition (PPE) est une option possible.",
        prepLink: "prophylaxie pré-exposition (PrEP)",
        prepRecommendation:
            "Puisque tes pratiques sexuelles présentent un risque potentiellement élevé de contracter le VIH, renseigne-toi sur la",
        prepRecommendation2: "auprès d’une clinique spécialisée.",
        retakeRecommendationExplanation:
            "Le dépistage répété des infections énoncées ci-dessus permettra de couvrir la période sérologique, soit la période maximale avant laquelle l’infection peut être détectée dans le sang.",
        retakeRecommendationHeader:
            "Afin d’assurer la validité des résultats, il est possible que certains tests doivent être effectués à nouveau, tout dépendant du temps écoulé entre l'exposition potentielle et le test de dépistage.",
        retakeRecommendationHIV: "VIH : contrôle à faire à partir du",
        retakeRecommendationHepatiteC:
            "Hépatite C : contrôle à faire à partir du",
        retakeRecommendationSyphilis: "Syphilis : contrôle à faire à partir du",
        urinaryScreening: "Dépistage urinaire",
        vaxRecommendation:
            "Ton profil de vaccination ne semble pas à jour. Renseigne-toi auprès de ton médecin de famille ou en clinique spécialisée. C’est simple et souvent gratuit!",
        combinedOralNaatTest: "Chlamydia/Gonorrhée gorge (TAAN)",
        itssSlug: "itss",
        appointmentUpdated: "Ton rendez-vous a été mis-à-jour",
    },
    expiredSection: {
        header: "Ton questionnaire a expiré.",
        restart: "Remplir le questionnaire à nouveau",
        message:
            "Ton questionnaire a expiré.\n\nLe délai de 45 jours accordé pour la prise de rendez-vous a été dépassé. Ton questionnaire médical n’est donc plus valide et une réévaluation complète est nécessaire.\n\nSi tu as des questions, tu peux nous écrire à info@prelib.com et un membre de l’équipe te répondra rapidement.",
    },
    resultsSection: {
        invalidResultLabel: "Non valide",
        undisclosedResultLabel: "En attente",
        toRetakeResultLabel: "À reprendre",
        negativeResultLabel: "Négatif",
        positiveResultLabel: "Positif",
        notDoneResultLabel: "Non effectué",
        cancelled: "Annulé",
        resultsNotReadyLine1:
            "Tes résultats sont toujours en attente, mais ils ne devraient pas tarder à arriver!",
        resultsNotReadyLine2:
            "Tu recevras un message lorsque tes résultats seront disponibles.",
        recommendations: "Recommandations :",
        recommendationsWhenCancel:
            "Bien que ton rendez-vous soit annulé, les conseils suivants s’appliquent toujours :",
        recommendationsWhenNoResult:
            "D’ici là, garde à l’esprit les recommandations suivantes :",
        recommendationsWhenNotDone:
            "Même si ton rendez-vous n’a pas encore eu lieu, les conseils suivants demeurent pertinents :",
        giveUsYourFeedback: "Tu as des commentaires?",
        thankYouForFeedback: "Merci pour tes commentaires!",
        rowHeader: (date) => `Rendez-vous du ${date}`,
        pendingInfo: {
            header: "Résultat en attente :",
            explanation:
                "Un prélèvement « en attente » signifie que tes résultats d'analyse en laboratoire n’ont pas encore été reçus. Ce délai est normal et le résultat te sera acheminé sous peu (prévoir 7 jours ouvrables supplémentaires).",
        },
        retakeInfo: {
            row1_1:
                "Un de tes prélèvements n’a pas pu être interprété par le laboratoire. ",
            row1_2:
                "Il peut s’agir d’une erreur du laboratoire ou d’un échantillon non conforme, mais ce n’est rien d'alarmant.",
            row2_1:
                "Prelib t’offre de reprendre gratuitement ce prélèvement en prenant un rendez-vous au centre dans les 45 prochains jours.",
            retakeButton: "Reprendre ton rendez-vous",
            rejectRetakeButton: "Tu ne veux pas reprendre un rendez-vous.",
        },
        schedulePhoneAppointment: {
            title:
                "Tu as été invité.e à un rendez-vous téléphonique. Choisis une date qui te convient.",
            scheduleButton: "Prendre rendez-vous",
        },
    },
    sectionSelection: {
        appointmentsSection: {
            canceledAppointments: "Tes rendez-vous annulés",
            header: "Tes rendez-vous",
            pastAppointments: "Tes rendez-vous passés",
            upcomingAppointments: "Tes rendez-vous à venir",
            missedAppointments: "Tes rendez-vous manqués",
        },
        makeScreeningSection: {
            date: "Rendez-vous",
            header: "Faire un dépistage",
            information: "Informations",
            medicalEvaluation: "Évaluation médicale",
            payment: "Paiement",
            recommendations: "Recommandations",
            expired: "Questionnaire expiré",
            tooltipMessage: "Tu as déjà un rendez-vous prévu.",
        },
        myProfileSection: {
            generalHealth: "Santé générale",
            header: "Mon profil",
            personalInformation: "Renseignements personnels",
            sexualHealth: "Santé sexuelle",
        },
        myResultsSection: {
            header: "Tes résultats",
            tooltipMessage: "Tu n’as aucun résultat.",
        },
        welcomeTextInfo: "Ton dernier dépistage remonte au",
    },
    resetPassword: {
        changeButtonText: "Reconfigurer",
        confirmationLabel: "Confirmer le mot de passe",
        header: "Nouveau mot de passe",
        invalidPasswordError: "Mot de passe invalide",
        passwordDontMatchError: "Les mots de passe ne sont pas identiques.",
        invalidTokenError: "Le jeton est non valide.",
        passwordLabel: "Mot de passe",
        successMessage:
            "Mot de passe enregistré! Tu peux maintenant te connecter.",
    },
    signUp: {
        accountCreated: "Ton dossier a été créé",
        alreadyHasAccount: "Tu as déjà un compte?",
        byEmail: "Par courriel",
        bySms: "Par SMS",
        confirmationResent: "Le courriel de confirmation a été envoyé à ",
        consentMessage:
            'J\'accepte les <a href="https://prelib.com/termes" rel="noopener noreferrer" target="_blank"> conditions d\'utilisation</a>.',
        emailConfirmationMessage:
            "Tu dois maintenant confirmer ton adresse courriel.",
        emailLabel: "Adresse courriel",
        firstNameLabel: "Prénom",
        formErrors: "Il y a des erreurs dans le formulaire",
        clickHere: "Clique ici pour te",
        header: "Crée ton compte Prelib",
        howDidYouHearAboutUs: {
            title: "Comment as-tu entendu parler de Prelib?",
            options: {
                search_engine: "Moteur de recherche (Google, Bing, etc.)",
                social_media:
                    "Réseaux sociaux (Facebook, Instagram, Twitter, etc.)",
                recommendation:
                    "Recommandation d’un ami ou d’un membre de la famille",
                online_ads: "Publicités en ligne",
                news_article: "Article de presse",
                other: "Autre",
            },
            pleaseSpecify: "Veuille préciser",
            selectOption: "Choisis une option...",
        },
        hotmailWarning:
            "Si tu utilises @hotmail, les courriels Prelib risquent de se retrouver dans les courriels indésirables. Nous te recommandons d'ajouter Prelib à ta liste de contacts pour ne manquer aucun courriel",
        invalidEmailError: "Courriel non valide",
        invalidPasswordError: "Mot de passe non valide",
        lastNameLabel: "Nom de famille",
        login: "Connecte-toi",
        loginToAccount: "connecter",
        nameWarning: "Tel qu’indiqué sur ta carte RAMQ",
        optionalField: "*optionnel",
        passwordLabel: "Mot de passe",
        passwordWarning:
            "Ton mot de passe est strictement confidentiel et ne doit pas être divulgué à des tiers.",
        phoneNumberError: "Numéro de téléphone invalide",
        phoneNumberLabel: "Numéro de téléphone",
        phoneNumberPlaceholder: "(xxx) xxx-xxxx",
        requiredFieldError: "Champs requis",
        resend: "Renvoyer le courriel de confirmation",
        signUpButtonText: "Inscription",
        tfaLabel: "Active la vérification en deux étapes",
        tfaContext:
            "Quelle méthode préfères-tu pour recevoir ton code de vérification lorsque tu te connectes à Prelib?",
        warning: "Attention",
    },
    tfaInput: {
        title: "Authentification en 2 étapes",
        sentTo: (sentBy, sentTo) =>
            `Ton compte est protégé par une authentification en 2 étapes. Un code d’authentification à 6 chiffres a été envoyé par ${sentBy} à ${sentTo}.`,
        checkboxLabel:
            "Ne plus me demander le code pendant 30 jours lorsque j'utilise cet appareil.",
        fieldLabel: "Entrer le code à 6 chiffres :",
        codeError: "Code invalide",
        verifyButton: "Valider",
        verifySpanBoxTitle: "As-tu vérifié tes courriers indésirables?",
        verifySpanBoxSubTitle: "Le courriel vient de info@prelib.com",
        resentCode: (sentBy, sentTo) =>
            `Un code d’authentification à 6 chiffres a été renvoyé par ${sentBy} à ${sentTo}.`,
        resendCode: "Envoyer un nouveau code",
        sendNewCodeSuccess: "Un nouveau code a été envoyé",
        sendNewCodeError: "Erreur lors de l'envoi du nouveau code",
    },
    tfaSettings: {
        title: "Vérification en 2 étapes",
        subtitle:
            "Pour la sécurité de tes données, ton compte sera désormais protégé par une authentification en 2 étapes. Lors de la connexion, tu devras confirmer ton identité à l’aide d’un code à 6 chiffres. De quelle façon souhaites-tu recevoir ce code? ",
        confirmButton: "Continuer",
        bySms: "Par SMS",
        byEmail: "Par courriel",
        phoneConfirmationButton: "Confirmer le numéro de téléphone",
        phoneNumberLabel: "Numéro de téléphone",
        phoneNumberPlaceholder:
            "Format du numéro de téléphone : (xxx) xxx-xxxx",
        phoneNumberError: "Numéro de téléphone non valide",
    },
    select: {
        startTyping: "Commencer à écrire",
        keepTyping: "Continuer à écrire",
        noResults: "Aucun résultat",
    },
    months: [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
    ],
    humanizeDateFormatter: {
        unknownDate: "Aucune plage horaire n'est disponible pour le moment",
        nextAvailableTime: "La prochaine plage horaire disponible est",
        date: (day, shortMonth, hour) => `${day} ${shortMonth} à ${hour}`,
    },
    addressForm: {
        addressLine: "Adresse postale",
        city: "Ville",
        province: "Province",
        country: "Pays",
        postalCode: "Code Postal",
    },
    prepSection: {
        prepStatus: {
            title: "Statut PrEP",
            inactive: {
                title: "Aucun statut PrEP",
                description:
                    "Grâce à la Solution PrEP, tu peux obtenir la PrEP en toute simplicité. Ce service est disponible uniquement si le questionnaire indique que tu es éligible à la PrEP. Si tu soupçonnes une erreur t'ayant rendu inéligible, n'hésite pas à contacter notre équipe à info@prelib.com.",
                btn: "Prendre un RDV*",
                disclaimer:
                    "*Je consens à ce qu'une pharmacie participante au programme Solution PrEP reçoive mes coordonnées et les informations pertinentes à ma prescription et qu'elle me contacte selon la disponibilité choisie.",
            },
            active: {
                title: "Statut PrEP: Actif",
                description:
                    "Lorsque le statut de PrEP est actif, cela signifie que tu prends la prophylaxie pré-exposition (PrEP) via la Solution PrEP.",
                btn: "Arrêter le suivi PrEP",
                history: "Mon Historique",
                today: "Aujourd'hui",
                by: "par",
                expiration: "Date d'expiration du",
                status: {
                    ELIGIBLE: "Statut PrEP: Actif",
                    PENDING: "Statut PrEP: En attente",
                    INELIGIBLE: "Statut PrEP: Inactif",
                    REVOKED: "Statut PrEP: Inactif",
                    EXPIRED: "Statut PrEP: Inactif",
                },
            },
        },
        prepDelivery: {
            title: "Profil de livraison",
            disabledPopover: "Statut PrEP requis",
            inactive: {
                title: "Livraison inactive",
                description:
                    "La livraison est actuellement inactive pour ton compte. Pour activer et sélectionner ton profil de livraison, choisis l'option de livraison qui te convient. Ta demande sera ensuite examinée et confirmée par la pharmacie participante.",
            },
            active: {
                title: {
                    "1_MONTH":
                        "Mon plan en cours: Livraison planifiée (1 mois)",
                    "3_MONTHS":
                        "Mon plan en cours: Livraison planifiée (3 mois)",
                    ON_DEMAND: "Mon plan en cours: Livraison sur demande",
                    ON_PAUSE: "Livraison en pause",
                },
                nextDeliveryTitle: "Ma prochaine livraison",
                pastDeliveriesTitle: "Livraisons passées",
                futureDeliveriesTitle: "Livraisons futures",
                actionTooltip: {
                    activateDeliveryToEdit:
                        "Activer la livraison pour modifier",
                    editDate: "Modifier la date",
                    editDestination: "Modifier la destination",
                    activate: "Activer",
                    cancel: "Annuler",
                },
                tabHeader: {
                    deliveryDate: "Date de livraison",
                    status: "Statut",
                    address: "Adresse",
                    actions: "Actions",
                },
                editDate: {
                    title: "Choisis ta nouvelle date de livraison",
                    description:
                        "Choisir une nouvelle date souhaitée de livraison dans l'intervalle disponible du calendrier ci-dessous.",
                    continue: "Continuer",
                    reject: "Annuler",
                },
                editAddress: {
                    title: "Choisis ton mode de livraison",
                    destinationSelect: {
                        atHome: "À mon domicile",
                        postPickUp: "Cueillette en poste",
                        pharmacyPickUp: "Cueillette en pharmacie",
                        prelibPickUp: "Cueillette (Prelib)",
                    },
                    continue: "Continuer",
                    reject: "Annuler",
                    adresse: "Adresse",
                    city: "Ville",
                    zipCode: "Code postal",
                    province: "Province",
                    applyToAll:
                        "Je souhaite appliquer ce changement pour toutes les prochaines livraisons",
                    pharmacyPickUp: "Livraison à la Pharmacie du Village",
                    prelibPickUp: "Cueillette dans le centre Prelib: ",
                    on: "le",
                    atNextAppointment:
                        " lors de votre prochain rendez-vous de dépistage.",
                    validationMessages: {
                        address: "Veuillez indiquer votre adresse!",
                        city: "Veuillez indiquer votre ville!",
                        zipCode: "Veuillez indiquer votre code postal!",
                    },
                },
                cancelDelivery: {
                    title: "L’annulation de la livraison pour le mois en cours",
                    description:
                        "Si tu refuses une livraison planifiée, tu pourras toujours la réactiver avant la suivante. Une fois la livraison confirmée, tu peux l'annuler jusqu'à 72h avant la date prévue.",
                    cancel: "Annuler ma livraison",
                },
                deliverySummary: {
                    quantityLabel: "Quantité: ",
                    destinationLabel: "Destination de livraison: ",
                    addressLabel: "Adresse de livraison: ",
                    justificationLabel: "Message du pharmacien: ",
                    quantity: { "1_MONTH": "1 mois", "3_MONTHS": "3 mois" },
                    destination: {
                        RESIDENCE: "À mon domicile",
                        POST_PICK_UP: "Cueillette (Poste Canada)*",
                        PHARMACY_PICK_UP:
                            "Cueillette dans la Pharmacie du Village",
                        PRELIB_PICK_UP: "Cueillette (Prelib)",
                    },
                    pendingDeliveryDescription:
                        "Le statut de ta livraison est en attente. La livraison sera confirmée par le-la pharmacien-ne dans la semaine qui précède la livraison.",
                    confirmedAt: "Livraison confirmée le: ",
                    rejectedAt: "Livraison rejetée par le pharmacien le: ",
                    deliveredAt: "Livraison livrée le: ",
                    prelibPickUpDisclaimer: `*La date de livraison est celle de ton prochain rendez-vous de
              dépistage chez Prelib. Si ton rendez-vous est annulé, ta PrEP sera
              livrée selon le mode alternatif que tu préfères.`,
                    modify: "Modifier",
                    confirm: "Confirmer",
                    refuse: "Refuser",
                    pickUpAtPrelibOn: "Cueillette lors du dépistage le ",
                    pharmacieDuVillage: "Pharmacie du Village",
                    prelibPickUpInfo1:
                        "Si tu confirmes une livraison mais que tu changes d'avis, tu peux toujours l'annuler jusqu'à 72 heures avant la date prévue.",
                    prelibPickUpInfo2:
                        "Si tu refuses une livraison planifiée, tu as la possibilité de la réactiver avant la suivante.",
                    der: {
                        REJECTED:
                            "Le changement de date pour cette livraison a été rejetée, la livraison restera à la date originale.",
                        ACCEPTED:
                            "Le changement de date pour cette livraison a été acceptée, la livraison se fera à la nouvelle date.",
                    },
                    requestedDate: "Date demandée: ",
                },
                showMore: "Voir plus",
                showLess: "Voir moins",
                description: {
                    "1_MONTH":
                        "Le profil \"Livraison planifiée (1 mois)\" te permet de bénéficier d'une livraison de PrEP automatiquement chaque mois à condition que tu gardes ton dossier médical à jour. Cette option peut te permettre de bénéficier d'une plus grande flexibilité dans la gestion de ta PrEP, en évitant les oublis et les déplacements à répétition.",
                    "3_MONTHS":
                        "Le profil \"Livraison planifiée (3 mois)\" te permet de bénéficier d'une livraison de PrEP automatiquement chaque 3 mois à condition que tu gardes ton dossier médical à jour. Cette option peut te permettre de bénéficier d'une plus grande flexibilité dans la gestion de ta PrEP, en évitant les oublis et les déplacements à répétition.",
                    ON_DEMAND:
                        "Le profil \"Livraison sur demande\" te permet de bénéficier d'une livraison de PrEP lorsque tu le souhaites sans utiliser l'outil de planification automatisée.",
                    ON_PAUSE: "",
                },
                onDemand: {
                    quantityLabel: "Quantité souhaitée*:",
                    dateLabel: "Date de la livraison:",
                    datePlaceholder: "Sélectionner une date",
                    quantityError: "Veuillez choisir une quantité!",
                    dateError: "Veuillez choisir une date de livraison!",
                    submitBtnLabel: "Demander la livraison",
                    quantityOptions: {
                        "1_MONTH": "1 mois",
                        "3_MONTHS": "3 mois",
                    },
                    quantityDisclaimer: "",
                },
                status: {
                    REQUEST_IN_PROGRESS: "En demande",
                    PENDING: "En attente",
                    CANCELED: "Annulée",
                    CONFIRMED: "Confirmée",
                    DELIVERED: "Effectuée",
                    REJECTED: "Rejetée",
                },
                btn: "TODO",
                preferencesCTA: "Préférences",
            },
        },
        deliverySettingsForm: {
            title: "Mes préférences de livraison",
            subtitles: [
                "Remplis tes préférences de livraison ci-dessous.",
                "Tu pourras les ajuster par la suite.",
            ],
            date: {
                label: "Date de livraison",
                placeholder: "Choix de la date de livraison",
                warning:
                    "*Tu ne pourras pas sélectionner une option de livraison durant les 72 prochaines heures, le temps que le pharmacien procède à la confirmation de ta demande.",
            },
            frequency: {
                label: "Fréquence de livraison",
                placeholder: "Choix de fréquence de livraison",
                options: {
                    "1_MONTH": {
                        title: "Récurrente (chaque mois)",
                    },
                    "3_MONTHS": {
                        title: "Récurrente (chaque 3 mois)",
                    },
                    ON_DEMAND: {
                        title: "Sur demande*",
                        details:
                            "* Cette option te permet de demander une livraison lorsque tu le souhaites sans utiliser une planification automatisée.",
                    },
                    ON_PAUSE: {
                        title: "En pause*",
                        details:
                            "* Lorsque tu mets sur pause tes livraisons, ton statut PrEP est toujours actif. Si tu le souhaites, tu peux réactiver tes livraisons via ton profil de préférence.",
                    },
                },
            },
            destination: {
                label: "Destination de livraison",
                placeholder: "Choix de destination de livraison",
                options: {
                    RESIDENCE: {
                        title: "À mon domicile",
                    },
                    POST_PICK_UP: {
                        title: "Cueillette (Poste Canada)*",
                        details:
                            "* En sélectionnant la cueillette chez Poste Canada, un avis de livraison te sera acheminé au courriel qui figure à ton dossier lorsque ton colis sera prêt à être ramassé dans un point de service près de chez toi.",
                    },
                    PHARMACY_PICK_UP: {
                        title: "Cueillette dans la Pharmacie du Village",
                    },
                },
            },
            address: {
                label: "Adresse",
                placeholder: "ex: 1050 rue Lorem Ipsum",
            },
            city: {
                label: "Ville",
                placeholder: "ex: Montréal",
            },
            zipCode: {
                label: "Code Postal",
                placeholder: "ex: H3B0G3",
            },
            province: {
                label: "Province",
                value: "QC",
                tooltip: "Livraison disponible uniquement au Québec",
            },
            activateDeliveryCTA: "Activer la livraison",
            saveDeliverySettingsCTA: "Sauvegarder",
            cancelDeliverySettingsCTA: "Annuler",
        },
    },
    clinicSelection: {
        privateLaboratory: {
            cardTitle: "Résultats rapides uniquement",
            disclaimer:
                "Pour un dépistage sur place, vous comprenez que l’analyse en laboratoire public couverte par la RAMQ n’est pas offerte dans la clinique et acceptez les frais de laboratoire privé.",
        },
    },
    privateLaboratoryBanner: {
        title: "Laboratoire privé uniquement",
        waitTime: {
            results: "Résultats",
            quick: "rapides",
            in: "en",
            time: "24 à 48 heures",
        },
        cost: {
            lowRange: "150$",
            to: "à",
            highRange: "375$",
            location: "payable at the clinic",
            description: "(selon les tests à faire)",
        },
    },
} as Translations;
