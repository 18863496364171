import { DateTime } from "luxon";
import Appointment, {
    FollowUpType,
    isUpcoming as isUpcomingAppointment,
} from "../models/appointment";
import PhoneAppointment, {
    isUpcoming as isUpcomingPhoneAppointment,
} from "../models/phone-appointment";
import Clinic from "models/clinic";
import User from "models/user";

export const isAppointment = (
    app: Appointment | PhoneAppointment
): app is Appointment => {
    return "userQuestionnaire" in app;
};

export const isUpcoming = (app: Appointment | PhoneAppointment): boolean => {
    if (isAppointment(app)) {
        return isUpcomingAppointment(app);
    } else {
        return isUpcomingPhoneAppointment(app);
    }
};

export const getAppointmentDate = (
    app: Appointment | PhoneAppointment
): DateTime | null => {
    const dateString = isAppointment(app) ? app.datetime : app.startAt;
    return dateString ? DateTime.fromISO(dateString) : null;
};

export const sortAppointments = (
    a: Appointment | PhoneAppointment,
    b: Appointment | PhoneAppointment,
    order: "asc" | "desc" = "asc"
): number => {
    const da = getAppointmentDate(a);
    const db = getAppointmentDate(b);
    if (!da) return 1;
    if (!db) return -1;

    if (order === "asc") return da > db ? 1 : -1;
    return da > db ? -1 : 1;
};

export const appointmentWithSatisfactionQuestionnairePrompt = (
    appointments: Appointment[]
): Appointment | undefined => {
    return appointments.find(
        (x) =>
            x.canTakeSatisfactionQuestionnaire === true &&
            x.resultsSeen === false
    );
};

export const googleReviewLinkForAppointment = (
    appointment: Appointment
): string => {
    return appointment.clinic?.googleLocationId
        ? `https://g.page/r/${appointment.clinic?.googleLocationId}/review`
        : // fallback that will search for the clinic on Google maps
          "https://www.google.com/maps/search/?api=1&" +
              new URLSearchParams({
                  query: `${appointment.clinic.address}+${appointment.clinic.city}`,
              }).toString();
};

export interface NextAppointment {
    dateTime: string;
    clinic?: Clinic | null;
    addToGoogleCalendarLink?: string;
    addToOutlookCalendarLink?: string;
    followUpType?: FollowUpType;
}

export function getNextAppointment(
    user: User | null | undefined
): NextAppointment | null {
    if (!user) return null;

    const appointment = [...user.phoneAppointments, ...user.appointments].find(
        (appointment) => appointment.status === "SCHEDULED"
    );

    if (!appointment) return null;

    const nextAppointment = isAppointment(appointment)
        ? {
              dateTime: appointment.datetime,
              clinic: appointment.clinic,
              addToGoogleCalendarLink: appointment.addToGoogleCalendarLink,
              addToOutlookCalendarLink: appointment.addToOutlookCalendarLink,
              followUpType: appointment.followUpType,
          }
        : {
              dateTime: appointment.startAt,
              clinic: null,
              addToGoogleCalendarLink: appointment.addToGoogleCalendarLink,
              addToOutlookCalendarLink: appointment.addToOutlookCalendarLink,
              followUpType: appointment.appointment?.followUpType ?? undefined,
          };

    return nextAppointment;
}
