import useLanguage from "../../hooks/language";
import React from "react";
import QuickResultsIcon from "../../assets/images/quick-results-icon.svg";
import styles from "./private-laboratory-banner.module.css";

export const PrivateLaboratoryBanner: React.FunctionComponent = () => {
    const { translations, language } = useLanguage();
    const resultDeliveryLocalization =
        translations.privateLaboratoryBanner.waitTime;
    const costLocalization = translations.privateLaboratoryBanner.cost;

    const getStyledResultDelivery = (): JSX.Element => {
        let text: JSX.Element;

        if (language === "en") {
            text = (
                <span>
                    <em>{resultDeliveryLocalization.quick}</em>
                    {" " +
                        resultDeliveryLocalization.results +
                        " " +
                        resultDeliveryLocalization.in +
                        " "}
                    <strong>{resultDeliveryLocalization.time}</strong>
                </span>
            );
        } else {
            text = (
                <span>
                    {resultDeliveryLocalization.results + " "}
                    <em>{resultDeliveryLocalization.quick}</em>
                    {" " + resultDeliveryLocalization.in + " "}
                    <strong>{resultDeliveryLocalization.time}</strong>
                </span>
            );
        }

        return text;
    };

    const getStyledChargeDescription = (): JSX.Element => {
        let costElement: JSX.Element;

        if (language === "en") {
            costElement = (
                <>
                    <strong>{costLocalization.lowRange}</strong>
                    {" " + costLocalization.to + " "}
                    <strong>{costLocalization.highRange}</strong>
                </>
            );
        } else {
            costElement = (
                <>
                    <strong>{costLocalization.lowRange}</strong>
                    {" " + costLocalization.to + " "}
                    <strong>{costLocalization.highRange}</strong>
                </>
            );
        }

        return (
            <span>
                <span className={styles["banner--cost-highlight"]}>
                    {costElement}
                </span>
                {" " + costLocalization.location}
            </span>
        );
    };

    return (
        <div className={styles["banner--container"]}>
            <div className={styles["banner--header"]}>
                <img src={QuickResultsIcon} alt="" />
                <span className={styles["banner--title"]}>
                    {translations.privateLaboratoryBanner.title}
                </span>
            </div>
            <div>
                <ul className={styles["banner--body"]}>
                    <li>{getStyledResultDelivery()}</li>
                    <li>
                        <div className={styles["banner--cost-container"]}>
                            {getStyledChargeDescription()}
                            <span
                                className={styles["banner--cost-description"]}
                            >
                                {costLocalization.description}
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default PrivateLaboratoryBanner;
