import { useIsFeatureFlagEnabled } from "pages/home/feature-flag-context/featureFlagContext";
import React from "react";
import QuickResultsIcon from "../../../assets/images/quick-results-icon.svg";
import useLanguage from "../../../hooks/language";
import Clinic, {
    ClinicLaboratoryModel,
    formatNextAvailableDate,
} from "../../../models/clinic";
import styles from "./clinic-card.module.css";

interface ClinicCardProps {
    clinic: Clinic;
    isSelected?: boolean;
}

const ClinicCard: React.FunctionComponent<ClinicCardProps> = ({
    clinic,
    isSelected,
}: ClinicCardProps) => {
    const { translations, language } = useLanguage();
    const dateString = formatNextAvailableDate(clinic, language);
    const isFeatureFlagEnabled = useIsFeatureFlagEnabled(
        "hide-next-available-date",
        "on"
    );

    return (
        <div className={styles.cardContainer}>
            <div className={styles.infoContainer}>
                <h3>{clinic.name}</h3>
                {isFeatureFlagEnabled ? (
                    <></>
                ) : (
                    <p>
                        {dateString ? (
                            <span>
                                {
                                    translations.humanizeDateFormatter
                                        .nextAvailableTime
                                }{" "}
                                <b>{dateString}</b>
                            </span>
                        ) : (
                            translations.humanizeDateFormatter.unknownDate
                        )}
                    </p>
                )}
                <a
                    href={`https://maps.google.com/?q=${clinic.address}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    {clinic.address}
                </a>

                {clinic.laboratoryModel === ClinicLaboratoryModel.Private &&
                    !isSelected && (
                        <div className={styles["laboratoryNote--container"]}>
                            <img src={QuickResultsIcon} alt="" />
                            <span className={styles["laboratoryNote__span"]}>
                                {
                                    translations.clinicSelection
                                        .privateLaboratory.cardTitle
                                }
                            </span>
                        </div>
                    )}
            </div>
        </div>
    );
};

export default ClinicCard;
